/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { 
  buildErrorMsgFromForm, 
  DateTool, 
  getConfig, 
  getManifestUrl, 
  getRawLicense, 
  isDev, 
  isLocal, 
  jparse, 
  orderHasManifest, 
  orderIsCheckedIn, 
  orderIsCheckedOut, 
  orderIsRejected, 
  stopProp, 
  uid, 
  userHasRole,
  size,
  cleanCopy,
  uploadManifestWithConfirm,
  wait,
  userIsSuspended,
} from '../../actions/utils';
import { withRouter } from 'react-router-dom';
import { Form, Input, Alert, Select, AutoComplete, DatePicker, Modal, message, Icon, Popover, Upload, Checkbox } from 'antd';
import SStyles from '../../_shared/styles';
import { Button } from '../../_shared/button';
import styles from './order.styles';
import { get, uniqBy, set, first, upperFirst, flatten, merge, uniq, orderBy, unset } from 'lodash';
import { createOrder, updateOrder, deleteOrder, cancelOrder, getOrder, signManifest } from '../../_shared/services/orders.service';
import ScreenTitle from '../../_shared/screen-title';
import { _Order, deliveryStatus, OrderStatus, Rejections } from '../../models/order';
import Link from '../../_shared/link';
import LNSearch from '../../_shared/ln-search';
import RouteSearch from '../../_shared/route-search';
import moment from 'moment';
import { sendEmail } from '../../_shared/services/email.service';
import colors from '../../_shared/colors';
import XLSX from 'xlsx';
import config from '../../config';
import { getUsersMatchingLn } from '../../_shared/services/authentication.service';
import { getRoutes } from '../../_shared/services/routes.service';
import { ROUTECOLS } from '../route/route.component';
import DriverSearch from '../../_shared/driver-search';
import { PrintLabelsButton } from '../../_shared/printlabels';
import Row from '../../_shared/row';
import ScreenComponent from '../../_shared/hooks/useScreen';
import { Sigpad } from '../../_shared/sigpad';

export const SHOULDSIGN = [OrderStatus.fullrejection, OrderStatus.partialrejection, OrderStatus.delivered, OrderStatus.inTransit];

const SubText = (text) => {
  return (
    <div css={css(styles.subText)}>
      {text}
    </div>
  )
}

class OrderComponent extends Component {
  constructor(props) {
    super(props);

    const self = this;

    this.state = {
      error: null,
      success: null,
      loading: true,
      isEdit: false,
      order: null,
      imageUrl: '',
      cancelModal: false,
      checkoutModal: { visible: false },
      destZip: null,
      pickupZip: null,
      allRoutes: [],
      showAllRoutes: false,
      showAllRoutesPickup: false,
      cancelInput: '',
      cashReturn: undefined,
      manifestUploading: false,
      notify: false,
      toDisplay: [
        {
          title: () => get(this, 'state.cashReturn') ? 'Cash Return Type' : 'Order Type',
          var: 'order.orderType',
          type: 'dropdown',
          opts: () => {
            // const { sState } = this.props;
            // const isAdmin = userHasRole([0], sState);

            const opts = [
              { title: 'Pickup with Layover', value: 'layover' },
              { title: 'Dropoff with Layover', value: 'dropoff' },
            ];

            const cashOptions = [
              { title: 'Cash Return', value: 'cash_return' },
              { title: 'Rejection Return', value: 'rej_return' },
              { title: 'Cash Pickup', value: 'cash_pickup' },
              { title: 'Other', value: 'other_return' },
            ];

            if (this.state.cashReturn) {
              //(cash return, rejection return, other)
              return cashOptions;
            }

            const hasSameDay = get(this.props, 'sState.user.sameDayPickup', false);
            const isAll = this.isAllAvail();

            if (hasSameDay || isAll) {
              opts.unshift({ title: 'Same Day', value: 'sameDay' });
            }

            if (isAll) {
              return [
                ...opts,
                ...cashOptions,
              ]
            }

            return opts;
          },
          inputProps: () => ({
            onBlur: () => {
              const { form: { getFieldValue } } = this.props;
              const orderType = getFieldValue('order.orderType');

              this.setState({ cashReturn: orderType === 'cash_return' ? true : undefined })
            },
            disabled: userHasRole([2], props.sState),
          }),
          options: {
            rules: [  
              {
                required: true,
                message: 'Order Type is required. ',
              },
            ],
          },
        },
        {
          title: 'Originating License #',
          var: 'order.pickupLN',
          type: () => get(this, 'state.isDriverOrder') ? 'lnsearch' : userHasRole([0], props.sState) ? 'lnsearch' : 'dropdown',
          opts: () => {
            const userOpts = uniqBy([
              ...[get(this.props.sState.user, 'company[License #]')],
              ...get(this.props.sState.user, 'company.licenseNumbers', [])
            ]).map(ln => ({
              title: ln,
              value: ln
            }));
  
            return userOpts;
          },
          subText: (item) => {
            const { isEdit } = this.state;
            const { form: { getFieldValue }, sState } = this.props;
            const value = getFieldValue(item.var);
  
            if (value) {
              const lnstate = get(this, 'LNSEARCH.state', {});
              const lns = get(lnstate, 'licenseNumbersAll', []);
              const lic = lns.find(l => get(l, 'License #') === value);
  
              const phone = isEdit ? null : get(sState, 'user.company.phone', '');
  
              if (lic) {
                const DBA = get(lic, 'DBA');
                const address = get(lic, 'Street Address');
                const text = (address ? `Address: ${address}` : '') + (DBA ? `${address ? '- ' : ''}DBA: ${DBA}` : '') + (phone ? ` - Phone: ${phone}` : '');
                return SubText(text);
              }
            }
          },
          nmProps: {
            title: 'Originating License Address',
            var: 'order.pickupCompany[Street Address]',
            placeholder: 'Address',
            options: {
              validateTrigger: 'onBlur',
              rules: [
                {
                  required: true,
                  message: 'Originating License Address is required. ',
                },
              ],
            },
          },
          inputProps: function() { 
            if (userHasRole(2, props.sState)) { 
              const isDriverOrder = !!self.state.isDriverOrder;
              return { 
                disabled: !isDriverOrder,
              } 
            }
            if (!self.isAllAvail()) { return }
            return { 
              onNM: (val) => self.onNM(val, this),
              filterActive: false,
            }
          },
          options: {
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'License # is required. ',
              },
            ],
          },
        },
        {
          title: 'Destination License #',
          type: 'lnsearch',
          var: 'order.destinationLN',
          required: true,
          subText: (item) => {
            const { form: { getFieldError, setFields } } = this.props;
            const value = get(this, `${item.var}.state.value`);
  
            if (value) {
              const error = getFieldError(item.var);
              if (error) {
                setFields({
                  [item.var]: { errors: null }
                })
              }
              const lnstate = get(this, 'LNSEARCH.state', {});
              const lns = get(lnstate, 'licenseNumbersAll', []);
              // const allUsers = get(lnstate, 'allUsers', []);
              const lic = lns.find(l => get(l, 'License #') === value);
  
              //i basically turned this off because the request is pretty large
              // const comp = allUsers.find(u => {
              //   const ulns = [
              //     get(u, 'data.company[License #]'),
              //     ...get(u, 'data.company.licenseNumbers', [])
              //   ];
  
              //   if (lic && ulns.indexOf(get(lic, ['License #'])) > -1) {
              //     return true;
              //   }
              // })
  
              if (lic) {
                const DBA = get(lic, 'DBA');
                // const phone = get(comp, 'data.company.phone');
                const address = get(lic, 'Street Address');
                const text = (address ? `Address: ${lic['Street Address']} - ` : '') + (DBA ? `DBA: ${DBA}` : '');// + (phone ? ` - Phone: ${phone}` : '');
                return SubText(text);
              }
            }
          },
          nmProps: {
            title: 'Destination License Address',
            var: 'order.destCompany[Street Address]',
            placeholder: 'Address',
            options: {
              validateTrigger: 'onBlur',
              rules: [
                {
                  required: true,
                  message: 'Destination License Address is required. ',
                },
              ],
            },
          },
          inputProps: function() { 
            if (userHasRole(2, props.sState) && !self.state.isDriverOrder) { return { disabled: true } }
            if (!self.isAllAvail()) { return }
            return { 
              onNM:  (val) => self.onNM(val, this),
              filterActive: false,
            }
          },
        },
        {
          title: 'Preferred Delivery Date',
          type: 'datepicker',
          var: 'order.preferredDelivery',
          info: 'Preferred delivery dates are subject to change at the discretion of Green Parcel Service. Any changes to delivery date will be communicated.',
          canDisplay: () => !get(this, 'state.cashReturn'),
          inputProps: (item) => ({
            disabledDate: (date) => {
              return this.disabledDates(date, item);
            },
          }),
          options: {
            rules: [
              {
                required: true,
                message: 'Preferred Delivery Date is required.',
              },
            ],
          },
        },
        {
          title: 'Preferred Pickup Date',
          type: 'datepicker',
          var: 'order.preferredPickup',
          info: 'Preferred pickup dates are subject to change at the discretion of Green Parcel Service. Any changes to pickup date will be communicated.',
        },
        {
          title: 'Box count',
          var: 'order.weight',
          placeholder: '4',
          info: 'Please provide box or bundle count.',
          canDisplay: () => !get(this, 'state.cashReturn'),
        },
        {
          title: 'Notes',
          var: 'order.notes',
          type: 'textarea',
          placeholder: 
`- Temperature controlled delivery 
- Weight (Flower or Trim weight in lbs)
- Delivery time cutoff
- Special handling instructions
- etc...`,
          inputProps: () => ({ disabled: userHasRole(2, this.props.sState) }),
          styles: `clear: both;`,
        },
        {
          title: 'Billing Amount',
          var: 'billing.amount',
          placeholder: 'Billing amount',
          canDisplay: () => !get(this, 'state.cashReturn') || get(this, 'state.isDriverOrder'),
        },
        {
          title: 'Billing Invoice #',
          var: 'billing.invoice',
          placeholder: 'Billing Invoice number',
          canDisplay: () => !get(this, 'state.cashReturn'),
          options: {
            rules: [  
              {
                required: true,
                message: 'Billing Invoice number is required. ',
              },
            ],
          },
        },
        {
          title: 'Billing Method',
          var: 'billing.method',
          placeholder: 'Billing Method',
          canDisplay: () => !get(this, 'state.cashReturn'),
        },
        {
          title: 'Billing Method',
          var: 'billing.methodSelect',
          type: 'dropdown',
          opts: () => [
            { title: 'Net Terms', value: 'net' },
            { title: 'Check', value: 'check' },
            { title: 'Cash', value: 'cash' },
            { title: 'Samples', value: 'samples' },
          ],
          placeholder: 'Billing Method',
          canDisplay: () => !get(this, 'state.cashReturn') && userHasRole(2, this.props.sState),
        },
        {
          title: () => {
            const { form: { getFieldValue } } = this.props;
            const value = getFieldValue('billing.methodSelect');

            return value === 'check' ? 'Check #' : 'Cash bag #';
          },
          var: 'billing.methodSelectId',
          placeholder: 'Check or Cash bag #',
          canDisplay: () => {
            const { isEdit } = this.state;
            const { form: { getFieldValue }, sState } = this.props;
            const isDriver = userHasRole([2], sState);
            const value = getFieldValue('billing.methodSelect');

            const isDriverOrder = get(this, 'state.isDriverOrder');
  
            return isDriverOrder ? true : ((isEdit && isDriver) || isDriverOrder) && (value === 'cash' || value === 'check');
          }
        },
        {
          title: 'Manifest',
          var: 'billing.manifest',
          placeholder: getConfig('orderForm.billingManifestPlaceholder', 'Manifest number from METRC'),
          canDisplay: () => !get(this, 'state.cashReturn'),
          // inputProps: () => {
          //   // if (userHasRole(2, props.sState)) { return { disabled: true } }
          //   return {};
          // },
          inputProps: () => ({
            disabled: true,
          }),
          subText: () => {
            const { sState } = this.props;
            const isAdmin = userHasRole([0], sState);
            const { file } = orderHasManifest(get(this, 'state.order'));

            const upl = {
              name: 'file',
              multiple: false,
              accept: ".pdf",
              showUploadList: false,
              beforeUpload: async (file) => {
                const conf = window.confirm('Are you sure you want to upload a new manifest? This will overwrite the old one if one exists.');

                if (conf) {
                  try {
                    this.setState({ manifestUploading: true });
                    await uploadManifestWithConfirm(file, this.state.order);
                    message.success('Manifest uploaded successfully!');
                    this.componentDidMount();
                  } catch (err) {
                    message.error(`Failed to upload manifest: ${err.message}`);
                  } finally {
                    this.setState({ manifestUploading: false });
                  }
                }
              }
            };

            return (
              <Row style={{ width: 'auto', position: 'absolute', right: 0, top: 0 }}>
                {isAdmin && <Upload {...upl}>
                  <Button loading={this.state.manifestUploading} hoverText="Upload Manifest"><Icon type="upload" /></Button>
                </Upload>}
                {!!file && <Button hoverText="View Manifest" onClick={() => window.open(getManifestUrl(file), '_blank')}><Icon type="file-protect" /></Button>}
              </Row>
            )
          }
        },
        {
          title: 'Delivery Status',
          var: 'order.status',
          type: 'dropdown',
          opts: () => {
            const isDriver = userHasRole(2, this.props.sState);
            let stats = [...deliveryStatus];
            if (isDriver) {
              stats = stats.filter(f => f.value !== OrderStatus.orderReceived);
            }
            return stats;
          },
          styles: `
            border-top: 5px solid ${colors.highlight};
            padding-top: 20px;
            clear: both;
          `,
          canDisplay: () => !this.state.cashReturn && this.state.isEdit && userHasRole([0, 2], this.props.sState),
          onChange: (value) => {
            const { sState } = this.props;
            const isDriver = userHasRole([2], sState);

            const showSigs = getConfig('sigs.show');
            const hasSig = get(this.props, 'sState.user.signature');

            if (!showSigs) { return }

            if (isDriver && !!hasSig && value === OrderStatus.orderPickedUp && !get(this, 'state.order.data.order.signature.pickup')) {
              const o = { ...this.state.order };
              set(o, 'data.order.signature.pickup', { ...hasSig, date: new Date().toISOString() });
              this.setState({ order: o })
            }

            if (isDriver && !!hasSig && [OrderStatus.delivered, OrderStatus.fullrejection, OrderStatus.partialrejection, OrderStatus.inTransit].includes(value)) {
              const o = { ...this.state.order };
              set(o, 'data.order.signature.driver', { ...hasSig, date: new Date().toISOString() });
              this.setState({ order: o })
            }
          }
        },
        {
          title: 'Rejected Reason',
          var: 'order.rejected',
          type: 'dropdown',
          placeholder: 'Reason for Rejection',
          opts: Rejections,
          styles: `
            clear: both;
          `,
          options: {
            rules: [
              {
                required: true,
                message: 'Reason for rejection is required. ',
              },
            ]
          },
          canDisplay: () => {
            const { isEdit } = this.state;
            const { form: { getFieldValue }, sState } = this.props;
            const isAdmin = userHasRole([0, 2], sState);
            const value = getFieldValue('order.status');
  
            return isEdit && isAdmin && (value === OrderStatus.fullrejection || value === OrderStatus.partialrejection);
          }
        },
        {
          title: 'Rejected Text',
          var: 'order.rejectedText',
          type: 'textarea',
          placeholder: 'Reason for Rejection',
          opts: Rejections,
          styles: `
            clear: both;
          `,
          options: {
            rules: [
              {
                required: true,
                message: 'Reason for rejection is required. ',
              },
            ]
          },
          canDisplay: () => {
            const { isEdit } = this.state;
            const { form: { getFieldValue }, sState } = this.props;
            const isAdmin = userHasRole([0, 2], sState);
            const value = getFieldValue('order.rejected');
  
            return isEdit && isAdmin && value === 8;
          }
        },
        {
          title: 'Exception Notes',
          var: 'order.exception',
          type: 'textarea',
          placeholder: 'Missing product, missing paperwork, store closed, weather, etc...',
          styles: `
            clear: both;
          `,
          options: {
            preserve: true,
            rules: [
              {
                required: true,
                message: 'Exception notes are required',
              }
            ]
          },
          canDisplay: () => {
            const { isEdit } = this.state;
            const { form: { getFieldValue }, sState } = this.props;
            const isAdmin = userHasRole([0, 2], sState);
            const value = getFieldValue('order.status');
  
            return isEdit && isAdmin && value === OrderStatus.exception;
          }
        },
        {
          title: 'Pickup Date',
          var: 'order.pickupDate',
          type: 'datepicker',
          canDisplay: () => {
            const { isEdit } = this.state;
            const { form: { getFieldValue }, sState } = this.props;
            const isAdmin = userHasRole([0, 2], sState);
            const value = getFieldValue('order.orderType');
            const isRejected = getFieldValue('order.status') === OrderStatus.rejected;
            const isDriverOrder = get(this, 'state.isDriverOrder');
  
            return (isDriverOrder ? true : !isRejected) && isEdit && isAdmin && (value === 'layover' || value === 'cash_return' || value === 'cash_pickup');
          },
          options: {
            preserve: true,
          }
        },
        {
          title: 'Pickup Type',
          var: 'order.pickupType',
          type: 'dropdown',
          canDisplay: () => {
            const { form: { getFieldValue }, sState } = this.props;
            const canView = this.state.isEdit && userHasRole([0], sState);
            const value = getFieldValue('order.orderType');
            const isRejected = getFieldValue('order.status') === OrderStatus.rejected;
  
            return !isRejected && !this.state.cashReturn && canView && value !== 'dropoff';
          },
          opts: () => {
            return [
              { title: 'Layover Pickup', value: 'layoverpickup' },
              { title: 'Warehouse', value: 'warehouse' },
              { title: 'Same day pickups', value: 'regular' },
            ]
          },
          options: {
            initialValue: 'regular',
            validateTrigger: 'onBlur',
          },
        },
        {
          title: 'Pickup Route',
          var: 'order.pickupRoute',
          type: 'routesearch',
          zip: () => {
            const lic = getRawLicense(get(this.state, 'order.data.order.pickupLN'));
            return get(lic, 'Zip');
          },
          showAll: () => get(this.state, 'showAllRoutesPickup'),
          extraOption: () => <Checkbox style={{ position: 'absolute', top: -28, left: 98, width: 300 }} checked={get(this, 'state.showAllRoutesPickup')} onChange={e => this.handleAllOptionsChange(e, 'showAllRoutesPickup')}>Allow All Routes</Checkbox>,
          styles: `
            clear: both;
          `,
          canDisplay: () => {
            const { isEdit } = this.state;
            const { form: { getFieldValue }, sState } = this.props;
            const isAdmin = userHasRole([0], sState);
            const value = getFieldValue('order.orderType');
            const isRejected = getFieldValue('order.status') === OrderStatus.rejected;
  
            return !isRejected && isEdit && isAdmin && (value === 'layover' || value === 'cash_return' || value === 'cash_pickup');
          },
          onChange: v => {
            //clear out the driver value, so that the component will auto select the most relevant
            get(this, 'order.driver.pickup.setValue', () => 0)(undefined);
          }
        },
        {
          title: 'Pickup Route Driver',
          var: 'order.driver.pickup',
          type: 'driversearch',
          styles: `clear: both;`,
          route: () => this.props.form.getFieldValue('order.pickupRoute'),
          date: () => this.props.form.getFieldValue('order.pickupDate'),
          canDisplay: () => {
            const { isEdit } = this.state;
            const { form: { getFieldValue }, sState } = this.props;
            const isAdmin = userHasRole([0], sState);
            const value = getFieldValue('order.orderType');
            const isRejected = getFieldValue('order.status') === OrderStatus.rejected;
  
            return !isRejected && isEdit && isAdmin && (value === 'layover' || value === 'cash_return' || value === 'cash_pickup');
          }
        },
        {
          title: 'Drop Off Date',
          var: 'order.dropoffDate',
          type: 'datepicker',
          canDisplay: () => {
            const { form: { getFieldValue }, sState } = this.props;
            const canView = this.state.isEdit && userHasRole([0], sState);
            const value = getFieldValue('order.orderType');
  
            return canView && value === 'dropoff';
          },
        },
        {
          title: () => {
            const { form: { getFieldValue } } = this.props;
            const isRejected = getFieldValue('order.status') === OrderStatus.rejected;

            return get(this, 'state.cashReturn') || isRejected ? 'Return Date' : 'Delivery Date';
          },
          var: 'order.deliveryDate',
          type: 'datepicker',
          canDisplay: () => this.state.isEdit && userHasRole([0, 2], this.props.sState)
        },
        {
          title: () => {
            const { form: { getFieldValue } } = this.props;
            const isRejected = getFieldValue('order.status') === OrderStatus.rejected;

            return get(this, 'state.cashReturn') || isRejected ? 'Return Route' : 'Delivery Route';
          },
          extraOption: () => <Checkbox style={{ position: 'absolute', top: -28, left: 98, width: 300 }} checked={get(this, 'state.showAllRoutes')} onChange={this.handleAllOptionsChange}>Allow All Routes</Checkbox>,
          var: 'order.route',
          type: 'routesearch',
          zip: () => {
            const key = this.props.form.getFieldValue('order.destinationLN');
            const lic = getRawLicense(key);
            return get(lic, 'Zip');
          },
          showAll: () => get(this.state, 'showAllRoutes'),
          styles: `
            clear: both;
          `,
          canDisplay: () => this.state.isEdit && userHasRole([0], this.props.sState),
          onChange: v => {
            get(this, 'order.driver.delivery.setValue', () => 0)(undefined);
          }
        },
        {
          title: () => {
            const { form: { getFieldValue } } = this.props;
            const isRejected = getFieldValue('order.status') === OrderStatus.rejected;

            return get(this, 'state.cashReturn') || isRejected ? 'Return Route Driver' : 'Delivery Route Driver';
          },
          var: 'order.driver.delivery',
          type: 'driversearch',
          styles: `clear: both;`,
          route: () => this.props.form.getFieldValue('order.route'),
          date: () => this.props.form.getFieldValue('order.deliveryDate'),
          canDisplay: () => this.state.isEdit && userHasRole([0], this.props.sState)
        },
        {
          title: () => 'Billing Notes',
          var: 'order.billingNotes',
          type: 'textarea',
          styles: `clear: both;`,
          canDisplay: () => this.state.isEdit && userHasRole([0], this.props.sState)
        },
        {
          title: 'Dropoff Driver Signature',
          var: 'order.signature.dropoff',
          type: 'sigpad',
          canDisplay: () => {
            const { form: { getFieldValue } } = this.props;
            const value = getFieldValue('order.orderType');

            return getConfig('sigs.show') && value === 'dropoff';
          },
          props: () => ({
            ...get(this, 'state.order.data.order.signature.dropoff', {}),
            manifest: this.getManifestProps(),
            onSave: res => {
              const k = 'data.order.signature.dropoff';
              const o = { ...this.state.order };
              const result = { ...res, date: new Date().toISOString() };
              set(o, k, result);
              this.setState({ order: o });
            }
          })
        },
        {
          title: 'Pickup Driver Signature',
          var: 'order.signature.pickup',
          type: 'sigpad',
          canDisplay: () => {
            const { form: { getFieldValue }, sState } = this.props;
            const isAdmin = userHasRole([0, 2], sState);
            const value = getFieldValue('order.status');

            return getConfig('sigs.show') && isAdmin && [OrderStatus.orderPickedUp].includes(value);
          },
          props: () => ({
            ...get(this, 'state.order.data.order.signature.pickup', {}),
            manifest: this.getManifestProps(),
            onSave: res => {
              const k = 'data.order.signature.pickup';
              const o = { ...this.state.order };
              const result = { ...res, date: new Date().toISOString() };
              set(o, k, result);
              this.setState({ order: o });
            }
          })
        },
        {
          title: 'Driver Signature',
          var: 'order.signature.driver',
          type: 'sigpad',
          canDisplay: () => {
            const { form: { getFieldValue }, sState } = this.props;
            const isAdmin = userHasRole([0, 2], sState);
            const value = getFieldValue('order.status')

            return getConfig('sigs.show') && isAdmin && SHOULDSIGN.includes(value);
          },
          props: () => ({
            ...get(this, 'state.order.data.order.signature.driver', {}),
            manifest: this.getManifestProps(),
            onSave: res => {
              const k = 'data.order.signature.driver';
              const o = { ...this.state.order };
              const result = { ...res, date: new Date().toISOString() };
              set(o, k, result);
              this.setState({ order: o });
            }
          })
        },
        {
          title: 'Customer Signature',
          var: 'order.signature.customer',
          type: 'sigpad',
          canDisplay: () => {
            const { form: { getFieldValue }, sState } = this.props;
            const isAdmin = userHasRole([0, 2], sState);
            const value = getFieldValue('order.status');

            return getConfig('sigs.show') && isAdmin && SHOULDSIGN.includes(value);
          },
          props: () => ({
            ...get(this, 'state.order.data.order.signature.customer', { emails: get(getRawLicense(get(this, 'state.order.data.destCompany.License #')), 'emails', '') }),
            manifest: this.getManifestProps(),
            showEmails: true,
            disclaimerType: 'customer',
            showTags: this.props.form.getFieldValue('order.status') === OrderStatus.partialrejection,
            onSave: res => {
              const k = 'data.order.signature.customer';
              const o = { ...this.state.order };
              const result = { ...res, date: new Date().toISOString() };
              set(o, k, result);
              this.setState({ order: o });
            }
          })
        }
      ],
      bulkOrders: [],
    };
  }
  getManifestProps = () => {
    return orderHasManifest(this.state.order).file ? getManifestUrl(orderHasManifest(this.state.order).file) : null;
  }
  filterBasedOnRoles = items => {
    const { sState } = this.props;
    const isDriver = userHasRole([2], sState);

    //Billing invoice, manifest, method, amount, delivery status for driver role.

    const isCashReturn = get(this, 'state.cashReturn');

    const driverItems = isCashReturn ? [
      'order.orderType',
      'order.pickupLN',
      'order.destinationLN',
      'order.status',
      'order.pickupDate',
      'order.deliveryDate',
      'billing.amount',
      'billing.methodSelectId',
    ] : [
      'order.pickupLN',
      'order.destinationLN',
      'order.status', 
      'order.rejected',
      'order.rejectedText',
      'order.exception',
      'billing.invoice', 
      'billing.amount', 
      'billing.methodSelect',
      'billing.methodSelectId',
      'billing.manifest', 
      'order.notes',
      'order.signature.pickup',
      'order.signature.driver',
      'order.signature.customer',
    ];

    if (isDriver) {
      return orderBy(items.filter(item => {
        const showIndex = driverItems.indexOf(item.var);

        if (showIndex > -1) {
          item.order = showIndex;
          return true;
        }
      }), 'order');
    }
    
    return items;
  }
  onNM = (val, dispItem) => {
    const { toDisplay } = this.state;

    const has = (val || '').toLowerCase().indexOf('nm-') > -1;

    const index = toDisplay.findIndex(fi => fi.var === dispItem.var);
    const _var = get(dispItem, 'nmProps.var');
    const existIndex = toDisplay.findIndex(f => f.var === _var);
    const alreadyExists = existIndex != -1;

    if (alreadyExists && has) { return }

    if (alreadyExists && !has) {
      toDisplay.splice(existIndex, 1);
    } else {
      toDisplay.splice(index + 1, 0, dispItem.nmProps)
    }

    this.setState({ toDisplay: [...toDisplay] });
  }
  isAllAvail = () => {
    return !!get(this, 'props.state.allAvail', get(this, 'props.history.location.state.allAvail'));
  }
  handleAllOptionsChange = (e, sel = 'showAllRoutes') => {
    const showAllRoutes = e.target.checked;

    this.setState({ 
      [sel]: showAllRoutes, 
    });
  }
  getValidRoutesForZip = (item) => {
    let sel = 'order.destinationLN';
    if (item.var.indexOf('__') > -1) {
      const [f, ind] = item.var.split('__');
      sel = `order.destinationLN__${ind}`;
    }
    const { Zip } = get(this, `${sel}.getSelected`, () => ({}))();
    if (!Zip) {
      return [];
    }

    const validRoutes = [];
    get(this, 'state.allRoutes', []).map(r => {
      get(r, 'data.rows', []).map(rr => {
        if (rr.zipCode === Zip) {
          validRoutes.push(rr);
        }
      })
    })

    return validRoutes;
  }
  disabledDates = (date, item) => {
    const dayOpts = get(ROUTECOLS.find(f => f.dataIndex === 'dayOfTheWeek'), 'options', []);
    const validRoutes = this.getValidRoutesForZip(item);
    const allowedDays = flatten(validRoutes.map(vr => get(vr, 'dayOfTheWeek')));
    const isdatearr = DateTool.isDateArray(allowedDays);
    const allowedDayInts = allowedDays.map(d => get(dayOpts.find(opt => opt.value === d), 'val'));
    const isAll = this.isAllAvail();

    if (isAll) {
      return false;
    }

    const thisDay = date.day();
    const isBefore = moment(date).startOf('day').isBefore(moment().startOf('day'));
    return isBefore || (isdatearr ? !DateTool.dateInArr(date, allowedDays) : allowedDayInts.indexOf(thisDay) === -1);
  }
  handleSelectChange = (e, item) => {
    const {
      form: { setFields, setFieldsValue, getFieldValue, getFieldsValue },
    } = this.props;
    let errors = null;
    const isOrderType = item.var.indexOf('order.orderType') > -1;

    if (isOrderType && (e === 'layover' || e === 'sameDay' || e === 'cash_return' || e === 'cash_pickup')) {
      setTimeout(() => {
        setFieldsValue({ 'order.pickupType': e === 'sameDay' ? 'regular' : 'layoverpickup' });
        if (e === 'sameDay') {
          const value = getFieldValue('order.preferredDelivery');
          setFieldsValue({ 'order.deliveryDate': value });
          // var: 'order.preferredDelivery',
        }
      }, 50);
    } else if (item.var.indexOf('.pickupLN') > -1 || item.var.indexOf('.destinationLN') > -1) {
      const fnames = (() => {
        let pickupLN = 'order.pickupLN';
        let destinationLN = 'order.destinationLN';
        if (item.var.indexOf('__') > -1) {
          const [order,ordernum] = item.var.split('__');
          pickupLN += `__${ordernum}`;
          destinationLN += `__${ordernum}`;
        }
        return {
          pickupLN,
          destinationLN,
        }
      })();

      const fields = getFieldsValue([fnames.pickupLN, fnames.destinationLN]);
      set(fields, item.var, e);
      
      const pln = get(fields, fnames.pickupLN);
      const dln = get(fields, fnames.destinationLN);
      setFieldsValue({
        [fnames.pickupLN]: pln,
        [fnames.destinationLN]: dln,
      })
      
      if (!pln || !dln) { return }

      const pHasR = pln.indexOf('R') > -1;
      const dHasR = dln.indexOf('R') > -1 || dln.indexOf('610') > -1;

      const useRCheck = get(window, `APPCONFIG.useRCheck`, true);

      if (useRCheck && pHasR !== dHasR) {
        errors = [new Error('Incorrect license type. Please review Medical or Recreational')];
        setTimeout(() => {
          setFields({
            [fnames.pickupLN]: {
              value: pln,
              errors
            },
          })
          get(this, [fnames.destinationLN, 'onChange'], () => 0)(dln);
          get(this, [fnames.destinationLN, 'selectOption'], () => 0)(dln);
        }, 200);
      }
    } else if (item.onChange) {
      item.onChange(e);
    }
    
    this.forceUpdate();
    return errors;
  }
  componentWillUnmount = () => {
    this.Unmounted = true;
  }
  getAllRoutes = async () => {
    const allRoutes = await getRoutes({}).catch(() => []);
    this.setState({ allRoutes });
  }
  componentDidMount = async () => {
    this.getAllRoutes();
    const state = get(this, 'props.state', get(this, 'props.history.location.state'));

    if (state && state.editOrder) {
      const eorder = state.editOrder;
      const serverOrder = eorder.id ? await getOrder(eorder.id) : eorder || {};
      const extraProps = { ...state, editOrder: undefined };

      const iscashReturn = !!state.cashReturn || get(state, 'editOrder.data.order.cashReturn');
      this.state.cashReturn = iscashReturn;
      this.state.order = serverOrder;
      this.state.isEdit = true;
      if (state.isNewAdminOrder) {
        this.state.isNewAdminOrder = state.isNewAdminOrder;
        const origLic = this.state.toDisplay.findIndex(td => td.var === 'pickupLN');
        set(this.state, `toDisplay.${origLic}.type`, 'lnsearch');
      }

      if (state.isDriverOrder) {
        this.state.isDriverOrder = true;
      }

      const order = get(serverOrder, 'data');
      const showAllRoutes = get(order, 'order.showAllRoutes', false);
      const showAllRoutesPickup = get(order, 'order.showAllRoutesPickup', false);
      
      this.state.showAllRoutes = showAllRoutes;
      this.state.showAllRoutesPickup = showAllRoutesPickup;

      this.setState({ ...this.state }, async () => {
        const ln = get(order, 'order.destinationLN');
        this.setFieldsOriginal(order, extraProps);

        const checkForAvail = async () => {
          return new Promise(async (resolve) => {
            const dln = get(this, 'order.destinationLN');
            const dState = get(dln, 'state.licenseNumbers', []);
            if (this.Unmounted) {
              resolve();
            } else if (dState.length > 0) {
              dln.onChange(ln);
              dln.selectOption(ln);
              this.setFieldsOriginal(order, extraProps);
              await wait(510);
              resolve();
            } else {
              await wait(500);
              await checkForAvail();
              resolve();
            }
          })
        }
        await checkForAvail();

        if (state.showCheckout) {
          setTimeout(() => {
            this.checkoutorder({ forceCheckout: true });
          }, 500);
        }

        if (this.state.isEdit && userHasRole([0, 2], this.props.sState) && getConfig('sigs.show')) {
          this.setState({ notify: !get(order, 'emails.signed', false) });
        }

        setTimeout(() => {
          this.setState({ loading: false });
        }, 750)
      });
    } else {
      this.setState({ loading: false });
    }
  };
  sendEmail = async email => {
    if (!this.isAllAvail()) {
      if ((!email.to || email.notifyUsers) && email.order) {
        const ln = get(email, 'order.data.order.pickupLN');
        const users = await getUsersMatchingLn(ln);
        
        const conf = config();

        email.to = isDev() ? conf.devEmails : users.filter(u => {
          const em = get(u, 'email');
          const allowed = get(u, 'data.alerts.email', true);

          return !!em && !!allowed;
        }).map(u => get(u, 'email'));

        if (email.notifyAdmins) {
          email.to = uniq([
            ...email.to,
            ...conf.rejectionEmails,
          ])
        }

        email.to = uniq(email.to);
      }
      await sendEmail(email);
    }
  }
  submit = async (e) => {
    stopProp(e);
    if (this.state.loading) {
      return;
    }

    if (userHasRole(2, this.props.sState)) {
      const cont = window.confirm('Are you sure you want to update this order?');
      if (!cont) {
        return;
      }
    }

    this.state.loading = true;
    this.state.error = null;
    this.setState(this.state);

    const { form, sState } = this.props;

    //clear errors
    const resetObj = {};
    const vals = form.getFieldsValue();
    this.state.toDisplay.map(td => {
      const value = form.getFieldValue(td.var);
      set(resetObj, td.var, { value });
    })
    form.setFields(resetObj);

    form.validateFieldsAndScroll(async (err, _values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }

      //check dates
      const canContinueFromDateCheck = (vals) => {
        if (this.state.cashReturn) { return true }
        const dayOpts = get(ROUTECOLS.find(f => f.dataIndex === 'dayOfTheWeek'), 'options', []);
        const validRoutes = get(this, 'state.routes', []).filter(r => get(jparse(r.value), 'id').indexOf('__utility__') === -1).map(r => ({ ...r, value: jparse(r.value) }));
        const allowedDays = validRoutes.map(vr => get(vr, 'value.day'));
        const allowedDayInts = allowedDays.map(d => get(dayOpts.find(opt => opt.value === d), 'val'));

        const prefDelSelector = 'order.preferredDelivery';
        const prefDeliveryDate = get(vals, prefDelSelector); //in moment
        const prefDay = prefDeliveryDate.day();

        if (size(allowedDayInts) && allowedDayInts.indexOf(prefDay) === -1) {
          //days dont' match fail
          form.setFields({
            [prefDelSelector]: {
              errors: [new Error('Day chosen not allowed')]
            }
          })
          this.setState({ 
            loading: false, 
            error: `The preferred delivery date you selected doesn't match a delivery route option. Please select one of the days listed: ${allowedDays.map(v => upperFirst(v)).join(', ')}` 
          });
          return false;
        }

        const isAdmin = userHasRole([0], sState);
        if (isAdmin) {
          const orderType = get(_values, 'order.orderType');
          const pickupDate = get(_values, 'order.pickupDate');
          const pickupRoute = get(_values, 'order.pickupRoute');
          const delRoute = get(_values, 'order.route');
          const delDate = get(_values, 'order.deliveryDate');

          const errors = [];

          if (pickupDate && pickupRoute) {
            const { day } = jparse(pickupRoute);
            const pickupRouteDay = get(dayOpts.find(d => d.value === day), 'val');
            if (pickupDate.day() !== pickupRouteDay) {
              errors.push(`The pickup date selected does not match the pickup route day.`);
            }
          }

          if (delDate && delRoute) {
            const { day } = jparse(delRoute);
            const delRouteDay = get(dayOpts.find(d => d.value === day), 'val');
            if (delDate.day() !== delRouteDay) {
              errors.push(`The delivery date selected does not match the delivery route day.`);
            }
          }

          if (size(errors)) {
            return window.confirm(errors.join('\n\n'));
          }
        }

        return true;
      }

      try {
        const { bulkOrders, showAllRoutes, showAllRoutesPickup } = this.state;

        const orders = {};
        const isBulk = bulkOrders.length > 0;
        if (isBulk) {
          bulkOrders.map((bo) => {
            if(bo.divider) { return 0 };

            const spl = bo.var.split('__');
            set(orders, `${spl[1]}.${spl[0]}`, get(_values, bo.var));
          })
        } else {
          orders['0'] = { ..._values };
        }

        let done = [];
        const checkDone = () => {
          done.push(1);
          if(done.length >= Object.keys(orders).length) {
            const isEdit = this.state.isEdit;
            message.success(`Order ${isEdit ? 'updated' : 'created'} successfully!`);
            this.goBack(true);
          }
        }
        
        const currentUser = get(sState, 'user', {});
        const ordersToProcess = [];
        for (let key in orders) {
          const dln = `order.destinationLN`;
          const val = orders[key];
          const sel = dln + (isBulk ? `__${key}` : '');
          const comp = get(this, sel);
          const destinationLN = get(val, dln, get(comp, 'state.selectedOption'));
          const status = get(val, 'order.status', get(this.state, 'order.data.order.status', deliveryStatus[0].value));
          const lnexists = get(this, 'LNSEARCH.state.licenseNumbersAll', []).find(l => get(l, 'License #') === destinationLN);
          const hasDateApproved = get(val, 'order.dateApproved');
          const isDriver = userHasRole(2, this.props.sState);

          const values = {
            ...(get(this, 'state.order.data', {}) || {}),
            ...val,
            order: {
              ...get(val, 'order', {}),
              weight: get(val, 'order.weight') || '1',
              status,
              dateApproved: hasDateApproved ? hasDateApproved : status >= 1 ? moment().toISOString() : undefined,
              destinationLN,
              showAllRoutes,
              showAllRoutesPickup,
              cashReturn: this.state.cashReturn,
            }
          }

          if (!isDriver && !canContinueFromDateCheck(values) || this.handleSelectChange(destinationLN, { var: sel })) {
            return this.setState({ loading: false });
          }

          //no destinationLN picked. Have to do this because of our custom AutoComplete Input on form
          const value = get(values, dln);
          const isNM = (value || '').toLowerCase().indexOf('nm-') > -1 && this.isAllAvail();
          if (!isDriver && !isNM && (!value || !lnexists)) {
            this.props.form.setFields({
              [sel]: {
                value,
                errors: [new Error(!value ? 'Destination is required!' : "License number doesn't exist")]
              }
            });
            setTimeout(() => {
              this.props.form.setFields({
                [sel]: {
                  value,
                  errors: [new Error(!value ? 'Destination is required!' : "License number doesn't exist")]
                }
              });
            }, 500);
            
            this.setState({ 
              loading: false,
              error: 'Please fix the errors below.'
            });
            break;
          }

          const destLN = get(values, 'order.destinationLN');
          const pickupLN = get(values, 'order.pickupLN');
          const licenses = get(this, 'LNSEARCH.state.licenseNumbersAll', []);
          const destL = licenses.find(l => get(l, ['License #']) === destLN);
          const pickupL = licenses.find(l => get(l, ['License #']) === pickupLN);

          const _order = {
            ...values,
            pickupCompany: {
              ...pickupL,
              // ...get(pickupUser, 'data.company', {}), //no longer going to include the company profile information in the order
              ...get(values, 'order.pickupCompany', {}),
            },
            destCompany: {
              ...destL,
              // ...get(destUser, 'data.company', {}), //no longer going to include the company profile information in the order
              ...get(values, 'order.destCompany', {}),
            },
          }

          if (get(_order, 'billing.methodSelect')) {
            const methodVal = get(_order, 'billing.methodSelect');
            if (methodVal === 'cash') {
              set(_order, 'billing.method', `Cash ${get(_order, 'billing.methodSelectId', '')}`);
            } else if (methodVal === 'check') {
              set(_order, 'billing.method', `Check ${get(_order, 'billing.methodSelectId', '')}`);
            } else if (methodVal === 'net') {
              set(_order, 'billing.method', `Net Terms`)
            } else if (methodVal === 'samples') {
              set(_order, 'billing.method', `Samples`);
            }
          }

          const sigs = get(this.state, 'order.data.order.signature');
          if (sigs) { set(_order, 'order.signature', sigs) }

          //Dont need logo in order
          unset(_order, 'pickupCompany.logo');
          unset(_order, 'destCompany.logo');

          const order = new _Order(_order);

          order.createdBy = currentUser.id;
          order.updatedBy = currentUser.id;
          order.dateSubmitted = new Date().toISOString();
          order.deliveryDate = get(order, 'order.deliveryDate', order.deliveryDate);
          // order.deliveryDate = moment.utc(get(order, 'order.deliveryDate', order.deliveryDate)).format("YYYY-MM-DD HH:mm:ss");
          
          if (this.state.isEdit && !this.state.isNewAdminOrder && !this.state.isDriverOrder) { //can't be bulk, we are only editing a single order
            const oOrder = { ...this.state.order };
            order.createdAt = oOrder.data.createdAt;
            order.createdBy = oOrder.data.createdBy;
            order.dateSubmitted = oOrder.data.dateSubmitted;

            const merged = {
              ...oOrder,
              data: merge({}, order, {
                order: merge({}, get(oOrder, 'data.order', {}), get(order, 'order', {})),
              })
            }

            if (orderIsRejected(merged) && !get(merged, 'data.order.rejectedDate')) {
              set(merged, 'data.order.rejectedDate', new Date().toISOString());
            }

            await updateOrder(merged);

            const { hasFile } = orderHasManifest(merged);
            if (hasFile && !!size(cleanCopy(get(merged, 'data.order.signature')))) {
              await signManifest({ order: merged, opts: { notify: this.state.notify }});
            }

            const orderStatus = get(merged, 'data.order.status');
            const prefDelivery = moment(get(merged, 'data.order.preferredDelivery'));
            const delDate = moment(get(merged, 'data.order.deliveryDate'));
            const oDelDate = moment(get(oOrder, 'data.order.deliveryDate'));
            if (get(oOrder, 'data.order.status') !== orderStatus && (orderStatus === OrderStatus.exception || orderStatus === OrderStatus.fullrejection || orderStatus === OrderStatus.partialrejection)) {
              const dStatus = deliveryStatus.find(d => d.value === order.order.status);
              
              try {
                const isPartial = orderStatus === OrderStatus.partialrejection;
                if (orderStatus === OrderStatus.fullrejection || isPartial) {
                  let rejection = get(Rejections.find(r => r.value === get(merged, 'data.order.rejected')), 'title', 'Other');

                  if (rejection === 'Other') {
                    const rejectedText = get(values, 'order.rejectedText');
                    rejection = !!rejectedText ? rejectedText : 'Other';
                  }

                  const hasTags = get(oOrder, 'data.order.signature.customer.tags');
                  const tags = isPartial && hasTags ? `<br /><br />Please see rejected tags: ${hasTags}` : '';

                  this.sendEmail({
                    order: oOrder,
                    subject: `GPS Order ${isPartial ? 'Partially ' :''}Rejected (${oOrder.orderId})`,
                    html: `Your order was ${isPartial ? 'partially ' :''}rejected for ${rejection}. It will be returned back to you on our next pickup at your location. For questions or more information, please contact us at <a href="mailto:orders@greenparcelservice.com">orders@greenparcelservice.com</a> or give us a call at 303-632-8772.${tags}`,
                    // notifyAdmins: true,
                    notifyUsers: true,
                    to: [...(getConfig('rejectionEmails', config().rejectionEmails))],
                  })
                } else if (orderStatus === OrderStatus.exception) {
                  this.sendEmail({
                    order: oOrder,
                    subject: `GPS Order Exception for (${oOrder.orderId})`,
                    html: `Order Exception. <br /><br /><b>Notes:</b><br />${get(values, 'order.exception')}`,
                    to: [...(getConfig('exceptionEmails', config().exceptionEmails))],
                  })
                } else {
                  this.sendEmail({
                    order: oOrder,
                    subject: `GPS Order update (${oOrder.orderId})`,
                    html: `
                      Your order's status has recently been changed to ${dStatus.title}.
                    `
                  })
                }
              } catch (_err) {
                console.warn(_err);
              }
            } else if (!prefDelivery.isSame(delDate, 'day') && !oDelDate.isSame(delDate, 'day')) {
              try {
                this.sendEmail({
                  order: oOrder,
                  subject: `GPS Order update (${oOrder.orderId})`,
                  html: `Your order's preferred delivery date cannot be met. Your new delivery date is ${moment(delDate).format('L')}. For questions or more information, please contact us at <a href="mailto:orders@greenparcelservice.com">orders@greenparcelservice.com</a> or give us a call at 303-632-8772.`
                })
              } catch (_err) {
                console.warn(_err);
              }
            }

            checkDone();
          } else {
            ordersToProcess.push({ 
              data: order,
              userId: currentUser.id,
              deliveryDate: order.deliveryDate,
              orderId: `${pickupLN}-${uid()}`,
            });
          }
        }

        if (ordersToProcess.length === Object.keys(orders).length) {
          ordersToProcess.map(async (o) => {
            await createOrder(o);
            const alertNewOrders = getConfig('alertNewOrders');
            if (alertNewOrders && !isLocal) {
              await this.sendEmail({
                order: o,
                subject: 'New Order submitted',
                html: `New order submitted: ${o.orderId}`,
                to: alertNewOrders,
              })
            }
            checkDone();
          })
        }
      } catch (err) {
        console.error(err);
        message.error('Order creation failed!');
        this.setState({
          loading: false,
          error: `Order creation failed: ${err.message}!`
        })
      }
    });
  };
  navigate = (cb, shouldUpdate) => {
    const { isModal, onClose } = this.props;
    if (isModal && onClose) {
      onClose(shouldUpdate);
    } else {
      cb();
    }
  }
  deleteOrder = () => {
    Modal.confirm({
      title: 'Delete Order?',
      content: 'Are you sure you want to delete this order?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        this.setState({ loading: true });
        await deleteOrder(this.state.order);
        this.navigate(this.props.history.goBack, true);
      }
    })
  }
  setFieldsOriginal = (order, eprops) => {
    const { toDisplay } = this.state;
    const {
      form: { setFieldsValue },
    } = this.props;

    if (order && order.data) { return }

    toDisplay.map(item => {
      if (!item) { return }

      const val = get(order, item.var);
      if (val !== undefined) {
        setFieldsValue({ [item.var]: item.type === 'datepicker' ? val && moment(val) : val });
        if (item.type === 'routesearch') {
          get(this, [item.var, 'onChange'], () => 0)(val);
        }
      } else if (item.var === 'order.pickupType') {
        setTimeout(() => { 
          const val = get(order, 'order.orderType', 'layover');
          this.handleSelectChange(val, { var: 'order.orderType' });
        }, 500);
      } else if (item.var === 'order.signature.driver' && userHasRole([2], this.props.sState)) {
        const sig = get(this.props, 'sState.user.signature', {});
        setFieldsValue({ [item.var]: { ...sig, date: new Date().toISOString() } })
      }
    });
  };
  goBack = (shouldUpdate) => {
    const isCreate = `${get(this, 'props.history.location.pathname')}`.indexOf('dashboard/createOrder') > -1;
    if (isCreate) {
      return this.props.history.push('orders');
    }
    this.navigate(this.props.history.goBack, shouldUpdate);
  }
  processData = (data, sheet, book) => {
    try {
      const { toDisplay } = this.state;
      const td = [...toDisplay];
      const {
        form: { setFieldsValue },
        sState,
      } = this.props;

      const bulkOrders = [];
      const vals = [];

      const map = {
        'order.destinationLN': 'Destination License',
        'order.notes': 'Notes',
        'order.orderType': 'Order Type',
        'order.pickupLN': 'Originating License',
        'order.preferredDelivery': 'Prefered Delivery Date',
        'order.preferredPickup': 'Prefered Pickup Date',
        'order.weight': ['Weight/BoxCount', 'Box Count'],
        'billing.amount': 'Billing Amount',
        'billing.invoice': 'Billing Invoice #',
        'billing.method': 'Billing Method',
        'billing.manifest': 'Manifest',
      }

      const lns = get(this, 'LNSEARCH.state.licenseNumbersAll', []);
      const userLNS = uniqBy([
        ...[get(sState.user, 'company[License #]')],
        ...get(sState.user, 'company.licenseNumbers', [])
      ]);

      data.map((d, i) => {
        // const dat = {
        //   'Destination License': "404-44523",
        //   'Notes': "Requires refrigeration",
        //   'Order Type': "Pickup",
        //   'Originating License': "403-12345",
        //   'Prefered Delivery Date': 'Thu Mar 05 2015 23:00:00 GMT-0700 (Mountain Standard Time',
        //   'Weight/BoxCount': 2
        // }

        const dl = get(d, 'Destination License', '');
        const isNumber = isNaN(parseInt(dl.match(/\d+/))) ? false : true;
        if(!isNumber) { //find dest license number
          const ln = lns.find(l => {
            const name = get(l, 'Licensee', '').toLowerCase();
            const dn = dl.toLowerCase();
            return name.indexOf(dn) > -1 ? true : false;
          })
          set(d, 'Destination License', ln ? ln['License #'] : null);
        }

        bulkOrders.push({
          divider: `Order # ${i + 1}`,
        })
        td.map(t => {
          const _var = `${t.var}__${i}`;
          const mapper = map[t.var];
          let val = Array.isArray(mapper) ? get(d, mapper.find(m => get(d, m))) : get(d, mapper);

          if (t.var.indexOf('pickupLN') > -1) {
            if (userLNS.indexOf(val) === -1) {
              val = first(userLNS);
            }
          } else if (t.var.indexOf('preferredDelivery') > -1 || t.var.indexOf('preferredPickup') > -1) {
            //dates not coming over correctly from xlsx sheet
            const c = t.var.indexOf('preferredDelivery') > -1 ? 3 : 4;
            const cellval = XLSX.utils.encode_cell({ c, r: i + 1 });
            const dateMode = book.Workbook.WBProps.date1904;
            const { v } = sheet[cellval] || {};
            const newdate = !v ? moment() : XLSX.SSF.format('YYYY-MM-DD', v, { date1904: dateMode });

            val = val ? moment(newdate) : moment();
          } else if (t.var.indexOf('orderType') > -1) {
            const opts = t.opts();
            const topts = opts.map(o => o.title);

            if (topts.indexOf(val) === -1) {
              val = first(opts).value;
            } else {
              val = get(opts.find(o => o.title === val), 'value', val);
            }
          }

          vals.push({
            var: _var,
            val,
            type: get(t, 'type'),
          })
          bulkOrders.push({
            ...t,
            var: _var
          })
        })
      });

      this.setState({
        bulkOrders,
      }, () => {
        vals.map(v => {
          if (!v) { return }

          setFieldsValue({
            [v.var]: v.type === 'datepicker' ? moment(v.val) : v.val,
          })
        })
        setTimeout(() => {
          this.setState({ loading: false });
        }, 500);
      })
    } catch (err) {
      this.setState({
        loading: false,
        error: `Error: ${err.message}`
      })
    }
  }
  downloadTemplate = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const win = window.open(`${config().url}/static/media/Template.xlsx`, '_blank');
    win.focus();
  }
  toggleCancel = () => {
    this.setState({ cancelModal: !this.state.cancelModal, loading: false });
  }
  cancelOrder = async () => {
    try {
      const { cancelInput, order } = this.state;
      
      this.setState({ loading: true });
      set(order, 'data.cancelReason', cancelInput || 'Other');
      await cancelOrder(order);
      this.sendEmail({
        to: [
          (isDev() ? null : order.createdBy),
          ...config().adminEmails,
        ].filter(e => e),
        subject: `Order canceled (${order.orderId})`,
        html: `
          Order ${order.orderId} has been canceled.<br /><br />
          Vendor: ${get(order, 'data.pickupCompany.DBA', '')} (${get(order, 'data.pickupCompany.License #', '')})<br /><br />
          Destination: ${get(order, 'data.destCompany.DBA', '')} (${get(order, 'data.destCompany.License #', '')})
        `
      }).catch(err => console.error(err));
      this.toggleCancel();
      this.navigate(this.props.history.goBack, true);
    } catch (err) {
      this.setState({
        loading: false,
        error: `Error: ${err.message}`
      })
    }
  }
  checkinorder = (e) => {
    const checkedIn = orderIsCheckedIn(this.state.order);
    set(this, 'state.order.data.order.checkedin', checkedIn ? undefined : { 
      time: moment().toISOString(),
      user: get(this, 'props.sState.user.id'),
    })
    this.submit(e);
  }
  pickupOrder = (e) => {
    try {
      const checkedIn = orderIsCheckedIn(this.state.order);

      const conf = checkedIn ? window.confirm('This order has already been checked in. Are you sure you want to continue?') : true;

      if (conf) {
        set(this, 'state.order.data.order.checkedin', { 
          time: moment().toISOString(),
          user: get(this, 'props.sState.user.id'),
        })
      } else {
        return;
      }

      const yes = window.confirm('Are you sure you want to set this order to picked up?');
  
      if (yes) {
        const { form: { setFieldsValue } } = this.props;
        
        setFieldsValue({ 'order.status': OrderStatus.orderPickedUp });
        // set(this, 'state.order.data.order.status', OrderStatus.orderPickedUp);

        const sigKey = 'state.order.data.order.signature.pickup';
        const hasSig = get(this, sigKey);
        const userHasSig = get(this.props, 'sState.user.signature');
  
        if (!userHasSig) {
          throw new Error(`You don't have a signature currently assigned to your profile.`)
        }
  
        if (!hasSig && userHasSig) {
          set(this, sigKey, { ...userHasSig, date: moment().toISOString() });
        }
  
        this.submit(e);
      }
    } catch (err) {
      message.error(err.toString());
    }
  }
  checkoutorder = (e = {}) => {
    const { form: { getFieldValue, setFieldsValue } } = this.props;
    const checkedOut = e.forceCheckout ? false : orderIsCheckedOut(this.state.order);

    if (checkedOut) {
      set(this, 'state.order.data.order.checkedout', undefined)
      this.submit(e);
    } else {
      this.setState({
        checkoutModal: {
          visible: true,
          title: 'Checkout Order?',
          weight: getFieldValue('order.weight') || 1,
          onOk: () => {
            const weight = get(this, 'state.checkoutModal.weight', 1);
            set(this, 'state.order.data.order.weight', weight);
            set(this, 'state.order.data.order.checkedout', checkedOut ? undefined : { 
              time: moment().toISOString(),
              user: get(this, 'props.sState.user.id'),
            })
            setFieldsValue({ 'order.weight': weight });
            this.submit(e);
          },
          onCancel: () => {
            this.setState({ checkoutModal: { visible: false } });
          }
        }
      })
    }
  }
  selectRoute = (val, item) => {
    const { form: { setFieldsValue } } = this.props;

    item.onChange && item.onChange(val);
    setFieldsValue({ [item.var]: val });
  }
  render() { 
    const { sState, isModal, form: { getFieldDecorator, setFieldsValue }, screen } = this.props;
    const { 
      error,
      success,
      loading,
      order,
      bulkOrders,
      cancelModal,
      isNewAdminOrder,
      checkoutModal,
    } = this.state;

    const orderStatus = get(order, 'data.order.status', 10);

    const isSuspended = userIsSuspended(sState);

    const showBack = order && !isModal;

    const draggerProps = {
      name: 'file',
      multiple: false,
      accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
      beforeUpload: (file) => {
        this.setState({ loading: true });
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          try {
            let binary = "";
            const bytes = new Uint8Array(e.target.result);
            const length = bytes.byteLength;
            for (let i = 0; i < length; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            const oFile = XLSX.read(binary, { 
              type: 'binary', 
              cellDates: false,
              cellNF: false,
              cellText: false 
            });
            const sheet1 = oFile.Sheets[oFile.SheetNames[0]];
            const data = XLSX.utils.sheet_to_json(sheet1);
            this.processData(data, sheet1, oFile);
          } catch (err) {
            message.error(`Unable to process spreadsheet: ${err.message}`);
          }
        };
        fileReader.readAsArrayBuffer(file);
      }
    };

    const toDisplay = bulkOrders.length > 0 ? bulkOrders : this.filterBasedOnRoles(this.state.toDisplay);

    const checkedIn = orderIsCheckedIn(order);
    const checkedOut = orderIsCheckedOut(order);
    const isAdmin = userHasRole([0], sState);
    const isDriver = userHasRole(2, sState);
    const showNotify = getConfig('sigs.show') && (isDriver || isAdmin) && !!this.state.isEdit && !!size(get(this.state, 'order.data.order.signature', {}));
    const hasNotified = get(this.state, 'order.data.emails.signed', false);

    if (isSuspended) {
      return (
        <div className="Profile no-print" css={css(SStyles.container, styles.container)}>
          <Alert
            message={'Your account has been suspended. Please contact GPS to reactivate your account.'}
            type={'error'}
            css={css(styles.alertMargin)}
          />
        </div>
      )
    }

    return (
      <div className="Profile no-print" css={css(SStyles.container, styles.container)}>

        {showBack && <Link css={css(`margin-right: auto;`)} onClick={this.goBack}>{`< Back`}</Link>}

        {!order && bulkOrders.length === 0 && <Upload.Dragger css={css(styles.uploadContainer)} {...draggerProps}>
          <Button
            css={css(styles.dlTemplate)}
            onClick={this.downloadTemplate}
          >
            <Icon type="download" />
            <span>Download Template</span>
          </Button>

          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to bulk upload</p>
        </Upload.Dragger>}
        
        <ScreenTitle title={(
          <div css={css(styles.column)}>
            <div css={css(styles.row)}>
              <span>{order && !isNewAdminOrder ? "Edit Order" : "Create Order"}</span>{loading && <Icon css={css('margin-left: 5px;')} type="loading" />}
            </div>
            {order && !isNewAdminOrder && isAdmin ? (
              <div>
                <Button
                  loading={loading}
                  onClick={this.pickupOrder}
                  css={css(styles.button)}
                  disabled={loading}
                >
                  {'Order Picked Up'}
                </Button>
              </div>
            ) : null}

            {order && !isNewAdminOrder && isAdmin ? (
              <div>
                <Button
                  loading={loading}
                  onClick={this.checkinorder}
                  css={css(styles.button)}
                  type={checkedIn ? 'danger' : undefined}
                  disabled={loading}
                >
                  {checkedIn ? 'Remove Check In' : 'Check In Order'}
                </Button>
              </div>
            ) : null}

            {order && !isNewAdminOrder && !isAdmin && !isDriver ? (
              <div>
                <Button
                  loading={loading}
                  onClick={this.checkoutorder}
                  css={css(styles.button)}
                  type={checkedOut ? 'danger' : undefined}
                  disabled={loading}
                >
                  {checkedOut ? 'Remove Check Out' : 'Check Out Order'}
                </Button>
              </div>
            ) : null}

            {order && !isNewAdminOrder && !isDriver && <PrintLabelsButton order={order} />}
          </div>
        )} />

        {!!error && (
          <Alert
            message={error}
            type={'error'}
            closable
            css={css(styles.alertMargin)}
            onClose={() => this.setState({ error: null })}
          />
        )}

        {!!success && (
          <Alert
            message={success}
            type={'success'}
            closable
            css={css(styles.alertMargin)}
            onClose={() => this.setState({ success: null })}
          />
        )}

        <div css={css('position: absolute; left: -999999px;')}>
          <LNSearch
            ref={comp => this.LNSEARCH = comp}
          />
        </div>

        <div css={css(SStyles.formContainer, styles.formContainer, screen.isMobile && `width: 100%;`)}>
          <Form layout="vertical" onSubmit={this.submit}>
            {toDisplay.map((item, i) => {
              if (item.divider) {
                return <div css={css(styles.divider)}>{item.divider}</div>
              }

              const canDisplay = item.canDisplay ? item.canDisplay() : true;
              const label = item.info ? (
                <span>
                  <span>{typeof item.title === 'function' ? item.title() : item.title}</span>
                  <Popover overlayStyle={{ maxWidth: 250 }} content={item.info}>
                    <Icon css={css(`color: ${colors.highlight}; margin-left: 6px;`)} type="info-circle" theme="filled" />
                  </Popover>
                </span>
              ) : typeof item.title === 'function' ? item.title() : item.title;

              const type = typeof get(item, 'type') === 'function' ? item.type() : item.type;

              return !canDisplay ? null : (
                <Form.Item key={i} label={label} required={item.required} css={css(item.styles)}>
                  <div>
                    {getFieldDecorator(item.var, item.options)(
                      type === 'dropdown' && item.opts ? (
                        <Select id={item.var} placeholder={item.placeholder} onChange={e => this.handleSelectChange(e, item)} {...(get(item, 'inputProps', () => ({}))())}>
                          {(typeof item.opts === 'function' ? item.opts() : item.opts).map((opt, i) => (
                            <Select.Option key={i} value={opt.value}>
                              {opt.title}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : type === 'datepicker' ?
                      (
                        <DatePicker {...(item.inputProps ? item.inputProps(item) : {})} />
                      ) : type === 'autocomplete' ? (
                        <AutoComplete
                          placeholder={item.placeholder}
                          dataSource={typeof item.opts === 'function' ? item.opts() : item.opts}
                        />
                      ) : type === 'routesearch' ? (
                        <RouteSearch
                          ref={comp => set(this, item.var, comp)}
                          zip={item.zip()}
                          showAll={item.showAll()}
                          onSelect={val => {
                            this.selectRoute(val, item)
                          }}
                        />
                      ) : type === 'driversearch' ? (
                        <DriverSearch
                          ref={comp => set(this, item.var, comp)}
                          date={item.date && item.date()}
                          route={item.route && item.route()}
                          initialValue={get(order, `data.${item.var}`)}
                          onSelect={val => {
                            setFieldsValue({ [item.var]: val });
                          }}
                        />
                      ) : type === 'textarea' ? (
                        <Input.TextArea rows={4} placeholder={item.placeholder} {...get(item, 'inputProps', () => ({}))()} />
                      ) : type === 'lnsearch' ? (
                        <LNSearch
                          ref={comp => set(this, item.var, comp)}
                          onSelect={(e) => this.handleSelectChange(e, item)}
                          {...(item.inputProps ? item.inputProps() : {})}
                        />
                      ) : type === 'sigpad' ? <Sigpad {...get(item, 'props', () => ({}))()} />  : (
                        <Input placeholder={item.placeholder} {...(item.inputProps ? item.inputProps(item) : {})} />
                      )
                    )}
                    {item && item.subText && item.subText(item)}
                    {item && item.extraOption && item.extraOption(item)}
                  </div>
                </Form.Item>
              );
            })}

            <Modal
              title="Cancel Order?"
              visible={cancelModal}
              confirmLoading={loading}
              onOk={this.cancelOrder}
              onCancel={this.toggleCancel}
              okText="Yes"
              cancelText="Cancel"
            >
              <p>Are you sure you want to cancel this order?</p>
              <Select style={{ width: '100%' }} placeholder="Cancel Reason" onChange={e => this.setState({ cancelInput: e })}>
                {[
                  { title: 'Vendor Cancelled' },
                  { title: 'Receiver Cancelled' },
                  { title: 'GPS Cancelled' },
                  { title: 'No Product' },
                  { title: 'Other' },
                ].map(opt => <Select.Option value={opt.title}>{opt.title}</Select.Option>)}
              </Select>
              {/* <Input placeholder="License Number" value={cancelInput} onChange={e => this.setState({ cancelInput: e.target.value })} /> */}
            </Modal>

            <Form.Item css={css`clear: both;`}>
              {showNotify && <Row>
                <Checkbox checked={this.state.notify} onChange={() => this.setState({ notify: !this.state.notify })}>{hasNotified ? "Re-Send to users" : "Send to users"}</Checkbox>
              </Row>}
              
              <div css={css(styles.buttonContainer)}>
                <Button
                  loading={loading}
                  onClick={this.submit}
                  type={"primary"}
                  css={css(styles.button)}
                >
                  {'Submit'}
                </Button>

                {order && orderStatus < OrderStatus.inTransit && !this.isAllAvail() && !isDriver && <Button
                  loading={loading}
                  onClick={this.toggleCancel}
                  outline={true}
                  css={css(styles.button)}
                >
                  {'Cancel Order'}
                </Button>}

                {userHasRole([0], sState) && order && <Button
                  loading={loading}
                  onClick={this.deleteOrder}
                  css={css(styles.button, `background-color: ${colors.error};`)}
                >
                  {'Delete Order'}
                </Button>}
              </div>
            </Form.Item>
          </Form>

          <Modal {...checkoutModal}>
            <p>Please confirm this is the correct box/bundle count?</p>
            <Input value={get(this, 'state.checkoutModal.weight', 1)} onChange={e => this.setState({ checkoutModal: { ...checkoutModal, weight: e.target.value } })} />
          </Modal>
        </div>
      </div>
    );
  }
}

export const Order = ScreenComponent(Form.create()(withRouter(OrderComponent)));
